<template>
    <div id="course-management">
        <leftbar-component></leftbar-component>
        <div class="content">
            <topbar-component :items="items"></topbar-component>
            <h1>学生管理</h1>
            <div class="search-bar">
                <div class="lf">
                    <div class="course-entrybox">
                        <i class="icon-search"></i>
                        <input v-model="userName" type="text" placeholder="请输入学生姓名/账号">
                    </div>
                    <div class="calendar">
                        <el-date-picker
                            popper-class="popDateBox"
                            v-model="searchDate"
                            type="daterange"
                            range-separator="~"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                    <div class="btn-search" @click="getStudentList(1)">查询</div>
                </div>
                <el-row type="flex" class="rf">
                    <el-button type="primary" @click="createAccount">新增学生账号</el-button>
                    <el-button type="success" @click="downloadImportTemplate">下载导入模板</el-button>
                    <el-upload ref="import" action=""
                               accept=".xls, .xlsx, .csv"
                               :auto-upload="false" :show-file-list="false"
                               :on-change="importExcelForStudentList" style="margin: 0 10px;">
                        <el-button type="warning">批量导入学生</el-button>
                    </el-upload>
                </el-row>
            </div>
            <div class="table">
                <el-table
                        :data="studentList"
                        style="width: 100%">
                    <el-table-column
                        prop="userId"
                        label="ID"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="userName"
                        label="学生账号">
                    </el-table-column>
                    <el-table-column
                        prop="userContact"
                        align="center"
                        width="100"
                        label="姓名">
                    </el-table-column>
                    <el-table-column
                        prop="useAddTime"
                        align="center"
                        label="新增时间">
                        <template slot-scope="scope">
                            {{ scope.row.useAddTime | formatDate }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        width="100"
                        label="操作"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)"
                                v-if="scope.row.status !== 1"
                            >编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <listH5Component
                    :list="studentList"
                >
                    <template v-slot:title="rowData" >
                        {{rowData.prop.userName}} {{rowData.prop.userContact}}
                    </template>
                    <template v-slot:datetime="rowData" >
                        {{rowData.prop.useAddTime | formatDateTime}}
                    </template>
                    <template v-slot:btn="rowData">
                        <span
                            @click="handleEdit(rowData.index,rowData.prop)"
                            v-if="rowData.prop.status !== 1"
                        >编辑
                        </span>
                    </template>
                </listH5Component>
                <div v-if="total > 10" class="pagination">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="getStudentList"
                        :current-page.sync="page"
                        :page-size="pageSize"
                        :total="total">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                :title="editing ? '编辑学生账号' : '新增学生账号'"
                width="35%"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                @close="closed"
                class="dialog"
            >
                <el-form ref="fm" :model="form">
                    <el-form-item label="学生账号" :label-width="formLabelWidth" v-if="editing">
                        <span class="account">{{ form.userName }}</span>
                    </el-form-item>
                    <el-form-item label="学生姓名" :label-width="formLabelWidth">
                        <el-input v-model="form.userContact" maxlength="8" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="userPhone" label="家长电话" :label-width="formLabelWidth"
                                  :rules="[
                                      {type: 'string', len: 11, message: '长度为 11 个字符'},
                                      {validator: validatePhone, message: '请填写正确的手机号码'}
                                  ]">
                        <el-input v-model="form.userPhone" type="tel" maxlength="11" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="账号密码" :label-width="formLabelWidth" v-if="editing">
                        <el-input v-model="form.userPass" type="password" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleSubmit">{{ editing ? '保存' : '新增' }}</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    import LeftbarComponent from './component/leftbarComponent';
    import TopbarComponent from './component/topbarComponent';
    import {urls} from '@/lib/common';
    import man from "../../plugins/man";

    import listH5Component from './component/listH5Component'

    /**
     * 验证手机号码
     * @param rule
     * @param value
     * @param callback
     */
    const validatePhone = (rule, value, callback) => {
        let reg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
        if (!(reg.test(value))) {
            callback(new Error());
        } else {callback();}
    };

    export default {
        name: "CourseManagement",
        components: {
            LeftbarComponent,
            TopbarComponent,
            listH5Component
        },
        data() {
            return {
                validatePhone,

                userName: '',
                page: 0,
                pageSize: 10,
                total: 0,
                items: [
                    {
                        text: '个人中心',
                        disabled: false,
                        href: '/userCenter',
                    }, {
                        text: '学生管理',
                        disabled: true,
                        href: '/courseManagement',
                    }
                ],
                studentList: [],
                studentIndex: null,
                searchDate: '',
                dialogVisible: false,
                editing: false,
                formLabelWidth: '120px',
                form: {
                    userName: '',
                    userContact: '',
                    userPhone: '',
                    userPass: ''
                },
            }
        },
        created() {
            this.getStudentList();
        },
        methods: {
            handleEdit(index, row) {
                let that = this;

                that.$http.get(`${urls.userServer.memberFrontFind}/${row.userId}`).then((res) => {
                    if(res.data.status === 200) {
                        that.form = {
                            ...res.data.data,
                        };
                        this.editing = true;
                        this.dialogVisible = true;
                    }
                });
            },
            createAccount() {
                this.form.userName = '';
                this.form.userContact = '';
                this.form.userPhone = '';
                this.form.userPass = '';
                this.editing = false;
                this.dialogVisible = true;
            },
            handleSubmit() {
                this.$refs["fm"].validate(valid => {
                    if (valid) {
                        const params = {
                            userContact: this.form.userContact,
                            userPhone: this.form.userPhone
                        }
                        let url = ''
                        if (this.editing) {
                            url = urls.editStudentAccount;
                            params.userId = this.form.userId;
                            params.userPass = this.form.userPass;
                        } else {
                            url = urls.addStudentAccount;
                        }
                        this.$http.post(url, params).then(res => {
                            // console.log(res);
                            if (res.data.status === 200) {
                                this.$message.success("添加成功");
                                this.dialogVisible = false;
                                this.getStudentList()
                            } else if (res.data.status !== 401 && res.data.status !== 106) {
                                this.$message.error(res.data.message)
                            }
                        })
                    }
                });
            },
            getStudentList(page = 1) {
                const params = {
                    pageCurrent: page,
                    pageSize: this.pageSize
                }
                if (this.userName) {
                    params.userName = this.userName
                }
                if (this.searchDate) {
                    const start = this.searchDate[0];
                    const end = this.searchDate[1];
                    const startDate = `${this.dateFormat(start)} 00:00:00`;
                    const endDate = `${this.dateFormat(end)} 23:59:59`;
                    params.startDate = startDate;
                    params.endDate = endDate;
                }
                this.$http.get(urls.getStudentList, {params}).then(res => {
                    console.log(res);
                    if (res.data.status === 200) {
                        this.studentList = res.data.data.records;
                        this.total = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            importStudentList(e) {
                if (e.target.files.length > 0) {
                    const file = e.target.files[0];
                    const fileFormat = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
                    if (fileFormat !== "xls" && fileFormat !== "xlsx" && fileFormat !== 'xlsm') {
                        this.$message.warning('请上传excel格式文件');
                        return false
                    } else {
                        if (file.size > 30 * 1000 *1000) {
                            this.$message.warning('上传文件不得大于30M');
                            return false
                        }
                        const params = {file}
                        console.log(params)
                        this.$http.post(urls.importStudentList, params).then(res => {
                            console.log(res);
                            if (res.data.status === 200) {
                                this.$message.success('导入成功');
                                this.getStudentList();
                            } else if (res.data.status !== 401 && res.data.status !== 106) {
                                this.$message.error(res.data.message)
                            }
                        })
                    }
                }
            },
            importExcelForStudentList: function(file) {
                let that = this;
                // console.log(file);return;

                const params = new FormData();
                params.append("file", file.raw);

                let fileNameArr = file.name.split('.');
                if(fileNameArr.length < 2 || !that.man.fast.inArray(fileNameArr[fileNameArr.length - 1].toLowerCase(), ['xls', 'xlsx', 'csv'])) {
                    that.$message.error('不支持的格式');
                    return false;
                }

                if(file.size > 30 * 1024 * 1024) {
                    that.$message.warning('上传文件不能大于 30 M');
                    return false;
                }


                that.$http.post(urls.userServer.memberFrontImport, params).then((res) => {
                    // console.log(res.data);
                    if(res.data.status === 200) {
                        that.$message.success('导入成功');
                        that.getStudentList();
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                }).catch((err) => {});
            },

            downloadImportTemplate: function() {
                let that = this;

                that.$http({
                    url: urls.userServer.memberFrontTemplate,
                    method: 'get',
                    responseType: 'blob',
                }).then((res) => {
                    man.file.blobAsFile(res, '学生列表导入模板', false, false);
                });
            },

            dateFormat(date) {
                const y = date.getFullYear();
                const m = date.getMonth() + 1;
                const d = date.getDate();
                const month = m >= 10 ? m : '0' + m;
                const day = d >= 10 ? d : '0' + d;
                return `${y}-${month}-${day}`
            },
            closed: function() {
                this.$refs["fm"].clearValidate();
            },
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #course-management {
        display: flex;
        justify-content: flex-start;
        .content {
            width: 84%;
            box-sizing: border-box;
            background: #F2FCFF;
            padding-bottom: 5%;

            >h1 {
                font-size: 1.25vw;
                margin: 1vw 0 1vw 2vw;
            }
            >.search-bar {
                margin: 1vw 0 1vw 2vw;
                width: 90%;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.73vw;
                .lf {
                    display: flex;
                    align-items: center;
                    .course-entrybox {
                        background-color: #fff;
                        margin-right: 10px;
                        line-height: 40px;
                        border-radius: 4vw;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #666;
                        .icon-search {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            background: url("../../assets/images/user-center/icon_search_dark.png") no-repeat center center;
                            background-size: 100%;
                            margin-left: 10px;
                        }
                        input {
                            padding: 0 15px 0 10px;
                        }
                    }
                    .calendar {
                        margin-right: 10px;
                        @{deep} .el-date-editor {
                            border: 0;
                            border-radius: 100px;
                            width: 240px;
                        }
                    }
                    .btn-search {
                        background-color: #24CEFF;
                        color: #fff;
                        line-height: 40px;
                        padding: 0 1.5vw;
                        cursor: pointer;
                        border-radius: 100px;
                        transition: all .3s linear;
                        &:hover {
                            background-color: #129DC6;
                        }
                    }
                }
                .rf {
                    label {
                        display: inline-block;
                        line-height: 1;
                        white-space: nowrap;
                        cursor: pointer;
                        background: #E6A23C;
                        border: 1px solid #E6A23C;
                        color: #fff;
                        -webkit-appearance: none;
                        text-align: center;
                        box-sizing: border-box;
                        outline: 0;
                        margin: 0 0 0 1vw;
                        transition: .1s;
                        font-weight: 500;
                        padding: 12px 20px;
                        font-size: 14px;
                        border-radius: 4px;
                        &:hover {
                            background-color: #ebb563;
                        }
                    }
                }
            }
            .table {
                width: 90%;
                margin: 1vw 0 1vw 2vw;
                @{deep} .el-table {
                    overflow: visible;
                    &::before {
                        width: 0;
                        height: 0;
                    }
                    thead {
                        th {
                            background-color: #24CEFF;
                            color: #fff;
                        }
                        th:first-child {
                            border-radius: 10px 0 0 0;
                        }
                        th:nth-child(5) {
                            border-radius: 0 10px 0 0;
                        }
                    }
                }
                .pagination {
                    text-align: right;
                    margin: 1vw 0 0 0;
                    @{deep} {
                        .el-pagination {
                            .el-pager li {
                                background-color: #fff;
                                &.active {
                                    background-color: #24CEFF;
                                }
                            }
                            .btn-next,
                            .btn-prev {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
            .dialog {
                @{deep} .dialog-footer {
                    text-align: center;
                }
                .account {
                    color: #999;
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #course-management {
            flex-direction: column;
            background: #F2FCFF;
            .content{
                width: 100%;
                margin-top:-40%;
                background: none;
                .topbar{
                    display: none;
                }
                &>h1{
                    background: #fff;
                    width: 94.7%;
                    margin:0 auto;
                    .fontFn(10,border-top-left-radius);
                    .fontFn(10,border-top-right-radius);
                    padding: 0 30px;
                    color:#34254C;
                    font-weight: bold;
                    .fontFn(18);
                    .fontFn(48,height);
                    .fontFn(48,line-height);
                }
                &>.search-bar{
                    background: #fff;
                    flex-direction: column;
                    .fontFn(10,border-bottom-left-radius);
                    .fontFn(10,border-bottom-right-radius);
                    width: 94.7%;
                    margin:0 auto;
                    padding:0 26px 3%;
                    margin-bottom: 5%;
                    box-shadow: 0px 5px 10px #D5F5FF;
                    .lf{
                        flex-direction: column;
                        width: 100%;
                        .course-entrybox{
                            .fontFn(20,border-radius);
                            width: 100%;
                            background: #F3F3F3;
                            margin-right: 0;
                            margin-bottom: 5%;
                            .fontFn(38,line-height);
                            .icon-search{
                                .fontFn(18,width);
                                .fontFn(18,height);
                                margin-left: 16px;
                            }
                            span{
                                color:#555859;
                                .fontFn(14);
                                .fontFn(26,line-height);
                            }
                            input{
                                width: auto;
                                flex-grow:1;
                                .fontFn(14);
                            }
                        }
                        .course-status-options{
                            width: 100%;
                            margin-bottom: 5%;

                            @{deep} .el-select{
                                width: 100%;
                            }

                            @{deep} .el-input__inner{
                                padding: 0 32px 0 16px;
                                .fontFn(14);
                                .fontFn(20,border-radius);
                                border:none;
                                background: #F3F3F3;
                                .fontFn(38,height);
                                .fontFn(38,line-height);
                            }
                        }
                        .calendar{
                            width: 100%;
                            margin:0 auto 5%;
                            .el-date-editor{
                                padding: 0;
                                width: 100%;
                                background: #F3F3F3;
                                .fontFn(20,border-radius);
                                @{deep} .el-range__icon{
                                    .fontFn(18);
                                    height: 32px;
                                    margin-left: 16px;
                                }
                                @{deep} .el-range-separator{
                                    height: 10px;
                                    line-height: 10px;
                                }
                                @{deep} .el-range-input{
                                    .fontFn(38,line-height);
                                    .fontFn(14);
                                }
                            }
                        }
                        .btn-search{
                            width: 100%;
                            text-align: center;
                            .fontFn(14);
                            .fontFn(38,height);
                            .fontFn(38,line-height);
                            margin-bottom: 5%;
                        }
                    }
                    .rf{
                        width: 100%;
                        text-align: center;
                        .fontFn(14);
                        .fontFn(38,height);
                        .fontFn(38,line-height);
                        margin-bottom: 5%;
                        @{deep} .el-button{
                            width: 33%;
                            padding: 0;
                            .fontFn(14);
                        }
                        &>div{
                            width: 33%;
                            margin-right: 0 !important;
                            @{deep} .el-upload{
                                width: 100%;
                                height: 100%;
                                .el-button{
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .table{
                    .el-table{
                        display: none;
                    }
                    width:94.7%;
                    margin:0 auto;
                }

                .el-dialog__wrapper{
                    @{deep} .el-dialog{
                        width: 96% !important;
                    }

                    @{deep} .el-dialog__body{
                        padding:20px 10px;
                    }

                    @{deep} .el-form-item__label{
                        width: 80px !important;
                    }

                    @{deep} .el-form-item__content{
                        margin-left:80px !important;
                    }

                    @{deep} .el-textarea__inner{
                        min-height: 120px !important;
                    }

                    @{deep} .el-row{
                        img{
                            width: 320px !important;
                            display: block;
                            margin:0 auto;
                        }
                    }
                }

                .dialog{
                    .courseware{
                        label{margin-bottom:3%;}
                        .courseware-form{
                            .search-bar{
                                flex-direction: column;
                                &>span{
                                    display: none;
                                }
                                .el-input{
                                    margin-bottom: 3%;
                                }
                                .el-range-editor.el-input__inner{
                                    width: 100% !important;
                                    margin:0 auto 3% !important;
                                }
                                .el-button{
                                    width: 100%;
                                    margin-bottom: 3%;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
</style>

<style lang="less">
@deep: ~'>>>';
@media screen and (max-width: 750px) {
    .el-dialog__wrapper{
        .el-dialog{
            width: 96% !important;
        }

        .vue-cropper{
            width: 100% !important;
        }

        .cropper-crop-box{
            transform: translate3d(0,0,0) !important;
        }
    }

    .el-message-box{
        width: 96%;
    }

    .popDateBox{
        width: auto !important;
        top:2% !important;
        left:2% !important;
        right:2% !important;
        margin:0 !important;
        background:rgba(0,0,0,0.5);

        .popper__arrow{
            display: none;
        }

        .el-picker-panel__body{

            min-width: auto !important;
            background: #fff !important;
        }

        .el-date-range-picker__content{
            float:none !important;
            width: 100% !important;
        }
    }
}
</style>